import { Tooltip, TooltipTrigger, TooltipContent } from "../FloatingTooltip";

const MenuToolTip = ({ children, text, offset = 5, color = "bg-black" }) => {
  return (
    <Tooltip offset={offset} placement="top" useSafePolygon={false}>
      <TooltipTrigger asChild={true}>{children}</TooltipTrigger>
      <TooltipContent className={`InPageTooltip ${color}`}>{text}</TooltipContent>
    </Tooltip>
  );
};

export default MenuToolTip;

{
  /* <MenuToolTip text="Ask minds to analyze active text">
<div
  onClick={() => {
    if (smallScreen) {
      editor.commands.blur(); // Necessary to remove the mobile browser helper.
    }
  }}
  className={`flex items-center justify-center py-[7px] pl-3 pr-2 text-black dark:text-msgray-300 dark:hover:text-white ${
    open ? "opacity-100" : "opacity-40"
  }`}
>
  <p>
    <CommentIcon className="me-1 inline h-4 w-4 " />
  </p>
</div>
</MenuToolTip> */
}
