import { Link } from "@remix-run/react";
import { ArrowLeft, ArrowLeftEm } from "~/components/Icons";
import { Tooltip, TooltipTrigger, TooltipContent } from "./FloatingTooltip";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { NavLink } from "@remix-run/react";
import { isMobile } from "react-device-detect";
import MenuToolTip from "./editor/MenuTooltip";

// Regular back button used to navigate between pages.
export function NavBackButton({ to = -1, color = "text-msgray-200", className = "" }) {
  return (
    <Link
      relative="path"
      to={to}
      preventScrollReset={true}
      className={`${color} active:text-msgray-400 dark:text-white md:hover:text-msgray-400 ${className}`}
    >
      <ArrowLeft />
    </Link>
  );
}

// Used in the mobile top bar to navigate between tabs.
export function BackButton({ onClick = () => {}, absolute = false }) {
  return (
    <button
      className={`${
        absolute
          ? "absolute left-[15px] top-[15px] z-10 rounded-full text-msgray-200 hover:text-black"
          : "text-msgray-200 active:text-msgray-400"
      } dark:text-white`}
      onClick={onClick}
    >
      <ArrowLeftEm className={absolute ? "h-[19px] w-[19px]" : "h-[23px] w-[23px]"} />
    </button>
  );
}

// Mobile menu button used in the top bar.
export function RegularMenuButton({
  Icon,
  iconParams = { className: "text-2xl" },
  onClick = () => {},
  onboardingHighlight = false,
}) {
  return (
    <button
      onClick={onClick}
      className={`${
        onboardingHighlight ? "highlight" : ""
      } text-msgray-200 active:text-msgray-400 dark:text-white`}
    >
      <Icon {...iconParams} />
    </button>
  );
}

export const NavSideButton = ({ className, Icon, to, title, smallScreen, side = "left" }) => {
  const navLink = (
    <NavLink
      prefetch="intent"
      to={to}
      className={({ isActive }) =>
        `${className || ""} ${
          isActive
            ? "text-msgray-900 hover:bg-msgray-50 dark:bg-gray-800 dark:text-white "
            : "text-msgray-200 hover:bg-msgray-50 hover:text-msgray-900 dark:text-gray-600 dark:hover:bg-gray-800 dark:hover:text-white"
        } flex h-11 w-11 items-center justify-center rounded-xl transition-all active:translate-y-[1px]`
      }
    >
      <Icon className="h-6 w-6" />
    </NavLink>
  );

  // If isMobile (this includes both mobile and tablets), return the NavLink directly
  if (isMobile) {
    return navLink;
  }

  // Otherwise, wrap it with Tooltip components
  return (
    <Tooltip placement={side === "left" ? "right" : "left"} useSafePolygon={false}>
      <TooltipTrigger asChild={true}>{navLink}</TooltipTrigger>
      <TooltipContent className={side === "left" ? "LeftMenuTooltip" : "RightMenuTooltip"}>
        {title}
      </TooltipContent>
    </Tooltip>
  );
};

// Side bar button - used in left or right sidebar, except for navigations. Gray bg on hover.
export const SideButton = ({
  title,
  Button,
  Icon,
  className = "",
  asChild = false, // set to true if using inside a Popover trigger.
  side = "left", // left or right
  extraParams,
  open,
  tooltipVisible = true,
  iconParams,
}) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile);
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const computedClassName = `${className} ${
    isOpen
      ? "bg-msgray-50 text-msgray-900 dark:bg-gray-800 dark:text-white"
      : "text-msgray-200 md:hover:bg-msgray-50 md:hover:text-black dark:text-gray-600 md:dark:hover:bg-gray-800 md:dark:hover:text-white"
  } 
  ${asChild ? "cursor-pointer" : ""}
  flex h-11 w-11 items-center justify-center rounded-xl transition-all active:translate-y-[1px]`;

  const buttonProps = {
    className: computedClassName,
    ...extraParams,
  };

  const buttonElement = (
    <Button {...buttonProps}>
      <Icon className="h-6 w-6" {...iconParams} />
    </Button>
  );

  if (isMobileDevice) {
    return buttonElement;
  }

  return (
    <Tooltip placement={side === "left" ? "right" : "left"} useSafePolygon={false}>
      <TooltipTrigger asChild={asChild}>{buttonElement}</TooltipTrigger>
      {tooltipVisible && (
        <TooltipContent className={side === "left" ? "LeftMenuTooltip" : "RightMenuTooltip"}>
          {title}
        </TooltipContent>
      )}
    </Tooltip>
  );
};

/**
 * Option button. In desktop, in-active selection is white (with borders) and active is blue.
 * In mobile, the default is same as desktop. When mobileDark is passed, we show the button using black background (and it has no selection state).
 * @param {*} param0
 * @returns
 */
export const OptionButton = ({
  onClick,
  optionValue,
  selected,
  title,
  description,
  mobileDark = false,
  children,
  extraTitle,
  isPremiumUser = true, // default to true.
}) => {
  const buttonClasses = classNames("relative w-full rounded-xl border p-4 text-left", {
    "!border-msgray-200 !bg-white": !isPremiumUser, // Overwrites others due to !.
    "border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700 dark:bg-blue-500 dark:text-black":
      selected,
    "bg-white text-gray-700 md:hover:bg-msgray-50 dark:bg-gray-200 border-gray-300":
      !selected && !mobileDark,
    "bg-black text-white dark:bg-gray-200 md:bg-white border-gray-300": mobileDark && !selected,
  });

  const divClasses = classNames("flex flex-col md:gap-2", {
    "items-start text-left gap-1.5": !mobileDark,
    "items-center md:items-start text-center md:text-left gap-0.5": mobileDark && !selected,
  });

  const titleClasses = classNames("text-xl font-normal md:text-xl", {
    "!text-msgray-200": !isPremiumUser, // Overwrites others due to !.
    "text-white": selected,
    "text-msgray-900": !selected && !mobileDark,
    "text-white md:text-msgray-900": mobileDark && !selected,
  });

  const extraTitleClasses = classNames("text-base font-light", {
    "!text-msgray-200": !isPremiumUser, // Overwrites others due to !.
    "text-white": selected,
    "text-msgray-300": !selected && !mobileDark,
    "text-white md:text-msgray-900": mobileDark && !selected,
  });

  const descriptionClasses = classNames("text-base", {
    "!text-msgray-200": !isPremiumUser, // Overwrites others due to !.
    "text-white": selected,
    "text-msgray-500": !selected && !mobileDark,
    "text-msgray-200 md:text-msgray-500": mobileDark && !selected,
  });

  const handleClick = () => onClick(optionValue);

  return (
    <button disabled={!isPremiumUser} onClick={handleClick} className={buttonClasses}>
      <div className={divClasses}>
        <p className={titleClasses}>
          {title}
          {extraTitle && <span className={extraTitleClasses}> {extraTitle}</span>}
        </p>
        <p className={descriptionClasses}>{description}</p>
      </div>
      {children}
    </button>
  );
};

// Main Button
export const BlackButton = ({
  id = undefined, // Not defined by default
  onClick,
  text,
  textDisabled,
  disabled,
  className,
  textSize = "text-lg md:text-xl",
  desktopRounded = true,
  mobileRounded = true,
  padding = "px-7 py-2",
  tooltip = null,
  hideUI = false,
}) => {
  const mobileRoundedClasses = mobileRounded ? "rounded-full" : "rounded-xl";
  const desktopRoundedClasses = desktopRounded ? "md:rounded-full" : "md:rounded-xl";
  const roundedClasses = `${mobileRoundedClasses} ${desktopRoundedClasses}`;

  const content = (
    <button
      id={id}
      onClick={onClick}
      disabled={disabled}
      className={`min-h-[50px] select-none 
    items-center ${padding} text-center ${textSize} border font-normal
    dark:bg-white dark:text-black ${roundedClasses} ${
        disabled
          ? "border-msgray-200 text-msgray-300"
          : `border-msgray-950  bg-msgray-950 text-white md:hover:border-msgray-800 md:hover:bg-msgray-800`
      } ${className}`}
    >
      {disabled ? (textDisabled ? textDisabled : text) : text}
    </button>
  );

  return tooltip && !disabled && !hideUI && !isMobile ? (
    <MenuToolTip color="bg-msgray-900" text={tooltip}>
      {content}
    </MenuToolTip>
  ) : (
    content
  );
};

export const DoubleButtons = () => {
  return (
    <div className="flex w-full gap-4">
      <BlackButton />
      <button className="flex-1 rounded-xl bg-white text-black">Hi!</button>
    </div>
  );
};
